import axios from 'axios'

export const fetchAdminContacts = async () => {
  try {
    const response = await axios.get(
      `${process.env.GATSBY_STRAPI_URL}/api/admin/get-contacts`
    )
    return response?.data || null
  } catch (e) {
    console.error(e)
    throw new Error('Une erreur est survenue')
  }
}
