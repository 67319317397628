import React, { useEffect, useState } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  Skeleton,
  TableFooter
} from '@mui/material'
import { routePaths } from '../../../constants/routes'
import { Message } from '../../../helpers/types'
import { useProfileContext } from '../../../contexts/profileContext'
import { SubContainer } from '../../common/Styled'
import Header from '../../common/Header'
import { Home, Mail, Settings } from '@mui/icons-material'
import { fetchAdminContacts } from '../../../helpers/api/contact'
import moment from 'moment'

const title = 'Contacts'
const items = [
  {
    icon: <Home />,
    label: 'Accueil',
    route: routePaths.home
  },
  {
    icon: <Settings />,
    label: 'Admin',
    route: routePaths.admin.index
  },
  {
    icon: <Mail />,
    label: title
  }
]

const Contacts = () => {
  const profile = useProfileContext()
  const [loading, setLoading] = useState(true)
  const [contacts, setContacts] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const isAdmin = profile?.isAdmin

  useEffect(() => {
    const fetchContacts = async () => {
      const result = await fetchAdminContacts()
      setContacts(result)
      setLoading(false)
    }
    if (isAdmin) fetchContacts()
  }, [isAdmin])

  if (!isAdmin) return null

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <SubContainer>
      <Header {...{ items, title }} />
      <TableContainer component={Paper} style={{ marginTop: 20 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Produit</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              new Array(3).fill('').map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))}
            {!loading &&
              contacts.length > 0 &&
              contacts.map(
                ({ id, name, message, email, toOrder, createdAt }) => {
                  return (
                    <TableRow key={id}>
                      <TableCell component="th" scope="row">
                        {id}
                      </TableCell>
                      <TableCell>{moment(createdAt).format('LLLL')}</TableCell>
                      <TableCell>{name}</TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>{toOrder}</TableCell>
                      <TableCell>{message}</TableCell>
                    </TableRow>
                  )
                }
              )}
            {!loading && contacts.length === 0 && (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  colSpan={5}
                >
                  Vous n&#39;avez pas encore de message
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              count={contacts?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </SubContainer>
  )
}

export default Contacts
