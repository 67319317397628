import React from 'react'
import SEO from '../../components/common/seo'
import ContactsView from '../../components/Admin/Contacts'

const Contacts = () => (
  <>
    <SEO title="Contacts" />
    <ContactsView />
  </>
)

export default Contacts
